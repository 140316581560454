


































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";

import {
  AuditFlowScope,
  CustomFormType,
  OrganizationUnitCreateOrUpdateDto,
  OrganizationUnitDto,
  OrganizationUnitDtoPagedResultDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "RoleList",
  components: {
    AgileAuditTag,
    PagedTableView,
    AbSelect,
  },
})
export default class OrganizationApplyList extends Vue {
  tableItems: OrganizationUnitDtoPagedResultDto[] = [];

  queryForm = {
    displayName: "",
    contactEmailAddress: "",
    contactMobilePhone: "",
    contactName: "",
    isApply: true,
  };

  statusList = [
    {
      value: "Pending",
      label: "待审核",
    },
    {
      value: "Approved",
      label: "已通过",
    },
    {
      value: "Rejected",
      label: "已拒绝",
    },
  ];

  // 获取表数据
  fetchData(params: any) {
    params.isApply = true;
    return api.organizationUnit.getAll(params);
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "auditFlow": {
        this.handleAuditFlow(e.index, e.item);
        break;
      }

      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
    }
  }

  // 查看详情
  // jumpDetail(index: number, row: OrganizationUnitCreateOrUpdateDto) {
  //   this.$router.push({
  //     name: "organizationApplyDetail",
  //     params: { id: row.id!.toString() }
  //   });
  // }
  async getCustomFormId(hostId: number | undefined, callBack: any) {
    await api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.OrganizationUnit,
        hostId: hostId + "",
      })
      .then((res) => {
        callBack(res);
      });
  }

  // 查看详情
  jumpDetail(index: number, row: OrganizationUnitDto) {
    this.getCustomFormId(row!.organizationType, (formId: string) => {
      this.$router.push({
        name: "orgDetail",
        query: {
          id: row.id!.toString(),
          formId: formId,
        },
      });
    });
  }

  // 删除
  async handleDelete(index: number, row: OrganizationUnitDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.organizationUnit.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  handleAuditFlow(index: number, row: OrganizationUnitDto) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.PartnerApply,
        hostId: row.id + "",
        auditFlowId: row.auditFlowId + "",
      },
    });
  }
}
